import { createApp } from 'vue'
import {
  Radio,
  Spin,
  Col,
  Row,
  Popconfirm,
  Form,
  Divider,
  Button,
  Layout,
  Drawer,
  Menu,
  Avatar,
  Dropdown,
  Input,
  Popover,
  Upload,
  Pagination,
  Select,
  Checkbox,
  Modal,
  Tag,
  Table,
  AutoComplete,
  Card,
  DatePicker,
  Steps,
  Slider,
} from 'ant-design-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'

import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './mixins.scss'

createApp(App)
  .use(store)
  .use(router)
  .use(Radio)
  .use(DatePicker)
  .use(Col)
  .use(Spin)
  .use(Row)
  .use(AutoComplete)
  .use(Pagination)
  .use(Popconfirm)
  .use(Form)
  .use(Divider)
  .use(Button)
  .use(Layout)
  .use(Steps)
  .use(Drawer)
  .use(Menu)
  .use(Avatar)
  .use(Dropdown)
  .use(Input)
  .use(Popover)
  .use(Upload)
  .use(Select)
  .use(Checkbox)
  .use(Modal)
  .use(Tag)
  .use(Table)
  .use(Card)
  .use(Slider)
  .use(PerfectScrollbar)
  .mount('#app')

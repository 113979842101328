export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  // {
  //   title: 'Dasbor',
  //   key: '__dasbor',
  //   url: '/dasbor',
  //   icon: 'fe fe-home',
  // },
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    // roles: ['admin'],
    icon: 'fe fe-monitor',
  },
  {
    title: 'Users',
    key: '__users',
    url: '/users',
    // roles: ['admin'],
    icon: 'fe fe-user',
  },
  {
    title: 'Create User',
    key: '__createUsers',
    url: '/create-users',
    // roles: ['admin'],
    icon: 'fe fe-user',
  },
  {
    title: 'Detail User',
    key: '__detailUsers',
    url: '/detail-users',
    // roles: ['admin'],
    icon: 'fe fe-user',
  },
  {
    title: 'Assets',
    key: '__assets',
    url: '/assets',
    // roles: ['admin'],
    icon: 'fe fe-grid',
  },
  {
    title: 'Collection Points',
    key: '__collectionPoints',
    url: '/collection-points',
    // roles: ['admin'],
    icon: 'fe fe-home',
  },
  {
    title: 'Hasil Test',
    key: '__hasilTest',
    url: '/hasil-test',
    // roles: ['admin'],
    icon: 'fe fe-clipboard',
  },
  {
    title: 'Tambah Test',
    key: '__tambahTest',
    url: '/tambah-test',
    // roles: ['admin'],
    icon: 'fe fe-file-plus',
  },
  {
    title: 'Dashboard',
    key: '__dashboard',
    url: '/dashboard',
    // roles: ['admin'],
    icon: 'fe fe-monitor',
  },
  // {
  //   title: 'Hasil Test',
  //   key: '__hasilTest',
  //   url: '/hasil-test',
  //   // roles: ['admin'],
  //   icon: 'fe fe-clipboard',
  // },

  {
    category: true,
    title: 'Menu Pendukung',
    // roles: ['admin'],
    key: '2t2ghm',
  },
  {
    title: 'Profil',
    key: '__akun',
    url: '/akun',
    // roles: ['admin'],
    icon: 'fe fe-users',
  },
  {
    title: 'Logout',
    key: '__logout',
    url: '/log-out',
    // roles: ['admin'],
    icon: 'fe fe-log-out',
  },

  // VB:REPLACE-END:MENU-CONFIG
]

<template>
  <a-menu-item :key="menuInfo.key" v-bind="$props" style="height: 81px; width: 135px;"
    class="d-flex align-items-center justify-content-center ">
    <router-link v-if="menuInfo.url" :to="menuInfo.url"
      class="d-flex flex-column align-items-center justify-content-center">
      <i :class="[styles.icon, menuInfo.icon]" :style="{ 'padding-left': settings.isMenuCollapsed ? '0.3em' : null }" />

      <span :class="styles.title">{{ menuInfo.title }}</span>
      <span v-if="menuInfo.count" class="badge badge-success ml-2">{{ menuInfo.count }}</span>
    </router-link>
    <!-- <span v-if="!menuInfo.url" :to="menuInfo.url">
      <i v-if="menuInfo.icon" :class="[styles.icon, menuInfo.icon]"
        :style="{ 'padding-left': settings.isMenuCollapsed ? '0.3em' : null }" />
      <span :class="styles.title">{{ menuInfo.title }}</span>
    </span> -->
  </a-menu-item>
</template>

<script>
import { Menu } from 'ant-design-vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'SubMenu',
  props: {
    ...Menu.Item.props,
    menuInfo: {
      type: Object,
      default: () => { },
    },
    styles: {
      type: Object,
      default: () => { },
    },
  },
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

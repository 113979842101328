import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import cookies from 'vue-cookies'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(''),
  routes: [
    // System Pages
    {
      path: '/',
      component: AuthLayout,
      meta: { authRequired: false, isAuth: false },
      name: 'auth',
      children: [
        {
          path: '/register',
          name: 'register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('@/views/auth/login'),
        },
        {
          path: '/success',
          name: 'success',
          meta: {
            title: 'Success',
          },
          component: () => import('@/views/auth/login'),
        },
      ],
    },
    {
      path: '/404',
      name: 'route404',
      meta: {
        title: 'Error 404',
      },
      component: () => import('./views/auth/404'),
    },
    {
      path: '/500',
      meta: {
        title: 'Error 500',
      },
      component: () => import('./views/auth/500'),
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)
  const storedUserData = localStorage.getItem('userData')
  const userData = JSON.parse(storedUserData)
  console.log('inirouter', userData)
  if (to.path === '/') {
    return next('/register')
  }
  // if (!userData) {
  //   if (to.path !== '/masuk') {
  //     return next('/masuk')
  //   }
  // }
  // else if (userData.user.role === 'siteManager') {
  //   return next()
  // } else {
  // }
  next()
})
//   // console.log(to, '<<<<<ROUTER')
//   // if (matched.route.role.includes(user.role)) {
//   //   next
//   // }
//   // const sessionId = cookies.get('sessionId')
//   // // console.log(to, from)
//   // if (to.matched.some(record => record.meta.authRequired)) {
//   //   if (!sessionId) {
//   //     next({
//   //       // name: 'masuk',
//   //       // query: { redirect: to.fullPath },
//   //     })
//   //   } else {
//   //     next()
//   //   }
//   // } else {
//   //   if (to.matched.some(record => record.meta.isAuth) && sessionId) next({ name: 'Master' })
//   //   next()
//   // }
// })

export default router

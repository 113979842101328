<template>
  <a-layout class="vb__layout">
    <a-layout-content>
      <div :class="{
        [$style.container]: true,
        vb__layout__squaredBorders: settings.isSquaredBorders,
        vb__layout__cardsShadow: settings.isCardShadow,
        vb__layout__borderless: settings.isBorderless,
        [$style.gray]: settings.authPagesColor === 'gray',
      }" class="justify-content-center">
        <div class="w-100 text-center">
          <!-- <img width="100" src="../../assets/img/logo_nodecenter.png"/> -->
        </div>
        <div>
          <router-view v-slot="{ Component }">
            <transition :name="settings.routerAnimation" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuthLayout',
  computed: mapState(['settings']),
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
